import React from "react";
import styles from "./styles.module.scss";

export const ChainItem = ({ name, img }) => {
  return (
    <div className={styles.ChainItem}>
      <img src={img} />
      <p>{name}</p>
    </div>
  );
};
