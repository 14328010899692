import React from "react";
import styles from "./styles.module.scss";
import gif from "../../assets/0_apBACOBeBmxmrP6U34.gif";
export const Container2 = () => {
  return (
    <div className={styles.Container_2}>
      {/** 
      <h1>Trustless Interchain Communication & Settlement</h1>
      <p>Access blockchain data & transfer liquidity from any chain</p>*/}
      <div className={styles.how_it_work}>
        {" "}
        <div className={styles.info_container}>
          <h1  className={styles.info_container_title}>How it all works</h1>
          <p className={styles.info_container_par}>
            Blockchain bridges are platforms that facilitate the transfer of
            assets and data from one blockchain ecosystem to another. They can
            be decentralized, centralized, or even hybrid. There are two ways
            bridges carry out asset transfer: wrapped assets or liquidity pool.
            Collapse bridge using this method often have staking and farming
            programs where users can lock their assets into the pool for
            periodic rewards. The bridge uses its locked assets to settle
            bridging requests.
          </p>
        </div>
        <div className={styles.gif_wrapper}>
          <img src={gif} className={styles.gif} />
        </div>
      </div>
    </div>
  );
};
