import React from "react";
import styles from "./styles.module.scss";
import { NavBar } from "../NavBar/NavBar";

import { BurgerMenu } from "../BurgerMenu/BurgerMenu";
import { Logo } from "../Logo/Logo";
export const Header = () => {
  return (
    <header className={styles.Header}>
      <Logo />
      <NavBar />
      <BurgerMenu />
    </header>
  );
};
