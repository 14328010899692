import React from "react";
import styles from "./styles.module.scss";
import { chains } from "../../storage";
import { ChainItem } from "../ChainItem/ChainItem";


export const Container3 = () => {
  return (
    <div className={styles.Container_3}>
      <h1>Widely Incorporated</h1>
      <p>
        Collapse supports the most-used L1 and L2 networks for seamless
        cross-chain UX
      </p>
      <div className={styles.chains_container}>
        {chains.map((n, index) => (
          <ChainItem name={n.name} img={n.img} key={index} />
        ))}
      </div>
    </div>
  );
};
