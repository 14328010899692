import React from "react";
import { Logo } from "../Logo/Logo";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { NavBar } from "../NavBar/NavBar";
export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_container}>
        <Logo />
        <NavBar forFooter={true} />
      </div>
      <div className={styles.rights}>
        <p>© 2024 Collapse. All rights reserved.</p>
      </div>
    </footer>
  );
};
