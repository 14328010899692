import React from "react";
import styles from "./styles.module.scss";
import { Container1 } from "../../components/Container_1/Container_1";
import { Container3 } from "../../components/Container_3/Container_3";
import { Container2 } from "../../components/Container_2/Container_2";
import { Container4 } from "../../components/Container_4/Container_4";
export const MainPage = () => {
  return (
    <main className={styles.MainPage}>
      <Container1 />
      <Container2 />
      <Container4 />
      <Container3 />
    </main>
  );
};
