import React, { useState } from "react";
import styles from "./styles.module.scss";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { TbArrowsExchange2 } from "react-icons/tb";
import { TbArrowsRandom } from "react-icons/tb";

import { GradientLine } from "../GradientLine/GradientLine";

export const Container4 = () => {
  return (
    <div className={styles.Container_4}>
      <GradientLine />
      <h1 className={styles.title}>Why Collapse?</h1>

      <div className={styles.why_collapse_wrapper}>
        <div className={styles.why_item}>
          <AiOutlineSafetyCertificate color="00ADAD" size={80} />
          <h2>Non-custodial + MPC</h2>
          <p>
            MPC, the powerful symbol of decentralization, processes Collapse
            cross-chain bridging and smart contract methods on other chains.
          </p>
        </div>
        <div className={styles.why_item}>
          <TbArrowsExchange2 color="00ADAD" size={80} />
          <h2>No-slippage Bridge</h2>
          <p>
            Collapse 1:1 bridge enables users to perform 0 slippage transfers
            and eliminate the hidden cost associated with AMM.
          </p>
        </div>
        <div className={styles.why_item}>
          <TbArrowsRandom color="00ADAD" size={80} />
          <h2>No-slippage Bridge</h2>
          <p>
            Collapse Router allows users to swap between any two chains freely.
            It reduces fees and makes it easier to move between chains.
          </p>
        </div>
      </div>
      <GradientLine />
    </div>
  );
};
