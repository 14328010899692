import { RouterProvider } from "react-router-dom";

import styles from "./styles.module.scss";
import { router } from "./routing";


function App() {
  return (
    <div className={styles.App}>
      <RouterProvider router={router} />
   
    </div>
  );
}

export default App;
