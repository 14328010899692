import React from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/clipart1397976.png";
import { NavLink } from "react-router-dom";
export const Logo = () => {
  return (
    <NavLink to={"/"}>
      <div className={styles.logo_wrapper}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.logo_title}>
          <h1>Collapse</h1>
          <p>Interchain Network</p>
        </div>
      </div>
    </NavLink>
  );
};
