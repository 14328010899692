import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { txt } from "../../storage";
import { GradientLine } from "../GradientLine/GradientLine";
import { Achievements } from "../Achievements/Achievements";

export const Container1 = () => {
  const [indexWord, setIndexWord] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndexWord((prevIndex) => (prevIndex + 1) % txt.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [txt.length]);

  return (
    <div className={styles.Container_1}>
      <div className={styles.main_title}>
        <div className={styles.title_row}>
          <p className={styles["first_word"]} key={indexWord}>
            {txt[indexWord].word_1}{" "}
          </p>
          <p className={styles.second_word}>
            <img src={txt[indexWord].img_1} className={styles.logo} />
            {txt[indexWord].word_2}{" "}
          </p>
        </div>
        <div className={styles.title_row}>
          <p className={styles.first_word}>{txt[indexWord].word_3} </p>
          <p className={styles.second_word}>
            <img src={txt[indexWord].img_2} className={styles.logo} />
            {txt[indexWord].word_4}{" "}
          </p>
        </div>
        <div className={styles.title_row}>
          <p className={styles.first_word}>{txt[indexWord].word_5} </p>
          <p className={styles.second_word}>
            <img src={txt[indexWord].img_3} className={styles.logo} />
            {txt[indexWord].word_6}{" "}
          </p>
        </div>
      </div>
      <GradientLine />
      <Achievements />
      <GradientLine />
    </div>
  );
};
