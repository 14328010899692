import React from "react";
import image from "../../assets/Pngtreeblockchain technology bitcoin ethereum_3814725.png";
import styles from "./styles.module.scss";
import { GradientLine } from "../../components/GradientLine/GradientLine";

export const AboutPage = () => {
  return (
    <main className={styles.AboutPage}>
      <GradientLine />
      <div className={styles.About_container}>
        <div className={styles.txt_wrapper}>
          <h1 className={styles.About_title}>About Collapse</h1>
          <img src={image} className={styles.mobile_img} alt="blockchain" />
          <p>
            Cross-chain bridges are decentralized applications that facilitate
            the transfer of the same asset between different blockchains. These
            bridges enable the movement of tokens with various standards (such
            as ERC-20, BEP-20, and others) across different blockchains,
            including those built on different technologies like BTC, Ethereum,
            Litecoin, Dogecoin. To facilitate transfers between blockchains,
            wrapped assets may be created, and liquidity pools in multiple
            ecosystems may be utilized. Relay nodes with liquidity in different
            blockchains can also execute fund transfers.
          </p>{" "}
        </div>

        <img src={image} className={styles.desctop_img} alt="blockchain" />
      </div>
      <GradientLine />
    </main>
  );
};
