// BurgerMenu.js
import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.classList.add(styles.bodyOverflowHidden);
    } else {
      body.classList.remove(styles.bodyOverflowHidden);
    }

    return () => {
      body.classList.remove(styles.bodyOverflowHidden);
    };
  }, [isOpen]);

  return (
    <div className={`${styles.burgerMenu} ${isOpen ? styles.open : ""}`}>
      <button className={styles.menuButton} onClick={toggleMenu}>
        {isOpen ? (
          <FaTimes className={styles.icon} size={30} color="red" />
        ) : (
          <FaBars className={styles.icon} size={30} color="#00ADAD" />
        )}
      </button>
      <div className={`${styles.menuContent} ${isOpen ? styles.visible : ""}`}>
        {/* Your menu items go here */}
        <ul className={styles.mobile_nav}>
          <NavLink to={"/about"} className={styles.link}>
            <li onClick={toggleMenu}>About</li>
          </NavLink>
          <NavLink to={"/realization"} className={styles.link}>
            <li onClick={toggleMenu}>Realization</li>
          </NavLink>
          <NavLink to={"/perspective"} className={styles.link}>
            <li onClick={toggleMenu}>Perspective</li>
          </NavLink>
          <NavLink to={"/support"} className={styles.link}>
            <li onClick={toggleMenu}>Support</li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
};
