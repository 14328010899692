import React from "react";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

export const NavBar = ({ forFooter }) => {
  return (
    <ul className={forFooter ? styles.NavBar_footer : styles.NavBar}>
      <NavLink to={"/about"}>
        <li>About</li>
      </NavLink>
      <NavLink to={"/realization"}>
        <li>Realization</li>
      </NavLink>
      <NavLink to={"/perspective"}>
        <li>Perspective</li>
      </NavLink>
      <NavLink to={"/support"}>
        <li>Support</li>{" "}
      </NavLink>
    </ul>
  );
};
