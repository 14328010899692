import { createHashRouter } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import { MainPage } from "./pages/MainPage/MainPage";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { RealizationPage } from "./pages/RealizationPage/RealizationPage";
import { PerspectivePage } from "./pages/PerspectivePage/PerspectivePage";
import { SupportPage } from "./pages/SupportPage/SupportPage";

export const router = createHashRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: <MainPage />,
        index: true,
      },
      { element: <AboutPage />, path: "/about" },
      { element: <RealizationPage />, path: "/realization" },
      { element: <PerspectivePage />, path: "/perspective" },
      { element: <SupportPage />, path: "/support" },
    ],
  },
]);
