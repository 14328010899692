import React from "react";
import { IoLogoDiscord } from "react-icons/io5";
import { BiLogoTelegram } from "react-icons/bi";
import { BiLogoGmail } from "react-icons/bi";

import styles from "./styles.module.scss";

export const SupportPage = () => {
  return (
    <main className={styles.SupportPage}>
      <div className={styles.SupportPage_content}>
        <div className={styles.left_container}>
          <h1>Support 24/7</h1>
          <p>
            Contact us if you have a question or feedback about our service. You
            can use one of the suggested communication options, such as discord,
            e-mail and telegram.
          </p>
          <div className={styles.support_wrapper}>
            <div className={styles.support_item}>
              <a href="https://discordapp.com/users/1217418685673635872">
                <IoLogoDiscord size={70} color="#00ADAD" />
              </a>
            </div>
            <div className={styles.support_item}>
              <a href="https://t.me/Collapse_Network">
                <BiLogoTelegram size={70} color="#00ADAD" />
              </a>
            </div>
            <div className={styles.support_item}>
              <a href="mailto:Collapse.InterchainNetwork@gmail.com">
                {" "}
                <BiLogoGmail size={70} color="#00ADAD" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
