import React from "react";
import styles from "./styles.module.scss";
import { GradientLine } from "../../components/GradientLine/GradientLine";


export const PerspectivePage = () => {
  return (
    <main className={styles.PerspectivePage}>
      <div className={styles.PerspectivePage_content}>
        <h1>Perspective</h1> <GradientLine />
        <p>
          What are the prospects for cross-chain bridges? Current trends
          indicate that a scenario where one blockchain dominates and displaces
          other solutions is unlikely. Despite Ethereum's broad capabilities,
          the emergence of L2 solutions offers significant advantages, including
          high transaction speed, lower fees, and greater flexibility and
          functionality.
        </p>
        <p>
          Several ecosystems based on EVM-compatible blockchains (Binance Smart
          Chain, Huobi ECO Chain, and others) are rapidly developing. The
          existence of multiple competing blockchains necessitates the need for
          cross-chain transfers.
        </p>
        <p>
          In these operations, stablecoins play a crucial role. These assets
          exist on different blockchains and are not subject to significant
          price fluctuations, making them convenient for storing value over an
          extended period.
        </p>
        <p>
          With the growing popularity of decentralized finance (DeFi),
          cross-chain bridges are becoming more preferable compared to
          traditional exchanges. The technology is in demand, and new projects
          are continually emerging, indicating the promising future of this
          direction.
        </p>{" "}
        <p>
          However, working with this technology often faces issues with
          unreliable L2 solutions, where tokens may be frozen after an exchange.
          In such cases, resolving the problem involves switching to a different
          L2 solution.
        </p>{" "}
      </div>
    </main>
  );
};
