import React from "react";

import styles from "./styles.module.scss";
import { GradientLine } from "../../components/GradientLine/GradientLine";


export const RealizationPage = () => {
  return (
    <main className={styles.RealizationPage}>
      <div className={styles.RealizationPage_content}>
        <h1>Realization</h1> <GradientLine />
        <p>
          Why are cross-chain bridges challenging to implement? The difficulty
          arises from the need for trust in exchange operations between users.
          Transactions between users wanting to trade Asset A for Asset B
          require a third party (guarantor) in the absence of trust. The
          guarantor receives Asset A from the seller and funds from the buyer
          (Asset B). After receiving funds from both users, the guarantor
          completes the exchange by transferring assets to each of them.
        </p>
        <GradientLine />
        <p>
          Traditional exchanges can act as guarantors in these transactions.
          However, in the context of decentralized exchanges and smart
          contracts, the process becomes more complex. Smart contracts can
          ensure asynchronous locking of funds from each user, followed by
          unlocking and transfer of the required assets to each user. This
          method, however, was not widely adopted due to the simultaneous
          availability requirement of both a buyer and a seller.
        </p>
        <GradientLine />
        <p>
          In existing non-custodial exchanges, liquidity providers (LP) lock
          funds in liquidity pools for exchanges. When users perform exchange
          operations, their funds enter a liquidity pool in one asset, and in
          return, they receive funds in another asset. All these operations are
          carried out using smart contracts and do not require a guarantor.
        </p>
        <GradientLine />
        <p>
          However, smart contracts can only execute within a single blockchain
          (e.g., Ethereum). When it comes to moving assets across different
          ecosystems, this approach falls short as smart contracts cannot
          interact with them. Special algorithms are needed for cross-chain
          transfers, often involving second-layer scaling solutions.
        </p>{" "}
        <GradientLine />
        <p>
          L2 smart contracts allow access to information from other ecosystems,
          including transaction details in Bitcoin, Ethereum, Binance Smart
          Chain (BSC), and more. They can also interact with external data
          through oracles.
        </p>{" "}
        <GradientLine />
        <p>
          How does a blockchain intermediary enable cross-chain bridges? One
          promising method for asset transfer between different networks
          involves using a specialized blockchain. The BTS - SEI project, for
          instance, employs this mechanism. Liquidity providers contribute funds
          to a pool and earn income in return.
        </p>{" "}
        <GradientLine />
        <p>
          The algorithm involves contributing funds to liquidity pools in two
          blockchains, with SEI being one of them. The project enables the
          exchange of assets from different blockchains with varying values. The
          exchange operation occurs in two stages through exchanges. Initially,
          the user exchanges the source blockchain's asset for BTS using the
          first liquidity pool.
        </p>{" "}
        <GradientLine />
        <p>
          In the second stage, the BTS token is exchanged for SEI in the
          destination blockchain, utilizing the second liquidity pool. This
          exchange, for example, from BTS to SEI, requires two liquidity
          providers. One provider offers USDT and BTS, while the other provides
          USDT and SEI. The operations are conducted through exchanges, and
          after the user submits a transfer request and specifies the SEI
          destination address, they need to transfer BTS to the
          exchange-provided address.
        </p>{" "}
        <GradientLine />
        <p>
          BTS goes to the first liquidity provider, who converts the
          corresponding value into SEI in the second liquidity pool. The second
          liquidity provider, after receiving SEI, transfers it to the user's
          specified address in the SEI blockchain. These operations are
          automated, and the liquidity providers' collateral funds, exceeding
          the value of funds used in exchange operations, serve as a guarantee
          of fairness. With two liquidity pools, assets can be exchanged in both
          directions.
        </p>
        <GradientLine />
        <p>
          Cross-chain bridges allow the transfer of stablecoins (such as USDT,
          USDC, etc.) between EVM-compatible blockchains like Ethereum, BSC,
          Huobi ECO Chain (HECO), and others. There are no restrictions on the
          types of assets or blockchains involved in exchange operations, as
          long as there are corresponding liquidity pools.
        </p>{" "}
        <GradientLine />
      </div>
    </main>
  );
};
