import React from "react";
import styles from "./styles.module.scss";

export const Achievements = () => {
  return (
    <div className={styles.Achievements}>
      <div className={styles.Achievements_item}>
        <h3>Total Interchain Transactions</h3>
        <p>8.94M</p>
      </div>
      <div className={styles.Achievements_item}>
        <h3>Total Interchain Volume</h3>
        <p>$42.21B</p>
      </div>
      <div className={styles.Achievements_item}>
        <h3>SIN Supported Chains</h3>
        <p>18</p>
      </div>
    </div>
  );
};
