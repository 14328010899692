export const txt = [
  {
    word_1: "Bridge",
    word_2: "SEI",
    img_1: require("./assets/SEI.webp"),
    word_3: "From",
    word_4: "Collapse",
    img_2: require("./assets/clipart1397976.png"),
    word_5: "to",
    word_6: "BTS",
    img_3: require("./assets/BitShares.png"),
  },
  {
    word_1: "Borrow",
    word_2: "USDC",
    img_1: require("./assets/usdc.png"),
    word_3: "on",
    word_4: "Polygon",
    img_2: require("./assets/polygon.png"),
    word_5: "from",
    word_6: "Optimism",
    img_3: require("./assets/optimism.png"),
  },
  {
    word_1: "Spend",
    word_2: "Gas",
    img_1: require("./assets/gaz.png"),
    word_3: "on",
    word_4: "Ethereum",
    img_2: require("./assets/ETH.png"),
    word_5: "from",
    word_6: "BSC",
    img_3: require("./assets/bsc.png"),
  },
];
export const chains = [
  { name: "Cardano", img: require("./assets/ada.webp") },
  { name: "Arbitrum", img: require("./assets/Arbitrum.png") },
  { name: "Bitcoin", img: require("./assets/bitcoin.webp") },
  { name: "Binance", img: require("./assets/bsc.png") },
  { name: "Canto", img: require("./assets/canto-network.webp") },
  { name: "Ethereum", img: require("./assets/ETH.png") },
  { name: "Fantom", img: require("./assets/Fantom_round.webp") },
  { name: "SEI", img: require("./assets/SEI.webp") },
  { name: "BTS", img: require("./assets/BitShares.png") },
  { name: "Hedera", img: require("./assets/Hedera.webp") },
  { name: "Mexc", img: require("./assets/MEX.png") },
  { name: "Metis", img: require("./assets/metis.png") },
  { name: "MOOI", img: require("./assets/MOOI.webp") },
  { name: "GATE IO", img: require("./assets/gateio.png") },
  { name: "Kucoin", img: require("./assets/Kucoin-exchange-logo.png") },
  { name: "Near", img: require("./assets/near.webp") },
  { name: "Optimism", img: require("./assets/optimism.png") },
  { name: "Polygon", img: require("./assets/polygon.png") },
  { name: "Solana", img: require("./assets/solana.webp") },
  { name: "HIVE", img: require("./assets/hive.png") },
  { name: "Tezos", img: require("./assets/Tezos-logo.webp") },
  { name: "Huobi", img: require("./assets/huoby.png") },
  { name: "Tron", img: require("./assets/tron-logo.webp") },
  { name: "Bybit", img: require("./assets/bybit.png") },
];
